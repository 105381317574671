import deepmerge from "deepmerge";

const endDateTime = new Date();
endDateTime.setHours(23, 59, 59, 0);

const startDateTime = new Date(endDateTime);
startDateTime.setHours(0, 0, 0, 0);

const DEFAULT_CONFIG = {
  api: {
    baseUrl: `${window.location.protocol}//${window.location.host}`,
    fetchOptions: {},
  },
  endDateTime,
  filters: {
    minAccuracy: null,
  },
  ignorePingLocation: true,
  locale: "ru-RU",
  map: {
    attribution:
      '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    circle: {
      color: null,
      fillColor: null,
      fillOpacity: 0.2,
    },
    circleMarker: {
      color: null,
      fillColor: "#fff",
      fillOpacity: 1,
      radius: 4,
    },
    controls: {
      scale: {
        display: true,
        imperial: true,
        maxWidth: 200,
        metric: true,
        position: "bottomleft",
      },
      zoom: {
        display: true,
        position: "topleft",
      },
    },
    heatmap: {
      blur: 15,
      gradient: null,
      max: 20,
      radius: 25,
    },
    layers: {
      heatmap: false,
      last: true,
      line: true,
      points: false,
    },
    maxNativeZoom: 17,
    maxPointDistance: 500,
    maxZoom: 19,
    polyline: {
      color: null,
      fillColor: "transparent",
    },
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
  onLocationChange: {
    fitView: false,
    reloadHistory: true,
    timer: null,
  },
  primaryColor: "#3f51b5",
  router: {
    basePath: "/",
  },
  selectedDevice: null,
  selectedUser: null,
  showDistanceTravelled: false,
  startDateTime,
  verbose: false,
};

(function(m,e,t,r,i,k,a){
  m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  m[i].l=1*new Date();
  k=e.createElement(t),
  a=e.getElementsByTagName(t)[0],
  k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})

(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(88604727, "init", {
  clickmap:false,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:false
});

// Use deepmerge to combine the default and user-defined configuration.
// This enables the user to use a fairly small config object which only
// needs to contain actual changes, not all default values - and these
// stay up-to-date automatically.
// There might not be a user-defined config, default to an empty object.
export default deepmerge(DEFAULT_CONFIG, (window.owntracks || {}).config || {});
